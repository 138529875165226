import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Slideshow = makeShortcode("Slideshow");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h3>{`1. Who isアーギ?`}</h3>
    <p>{`私はタトゥーアーティストです。 子供の頃から私はあらゆるものを作るのが大好きでした。 留学中に初めてタトゥーを入れたところ、その独特の気持ちと喜びが心に残りました。 卒業後は記者として、またマーケティングも担当しました。 忙しい日々の中で、仕事の意味について考え始め、自分がやっていることは本当に好きではないことに気づきました。 結局、自分の信念にこだわり、再びデザインへの情熱を取り戻し、女性のタトゥーアーティストになりました！`}</p>
    <h3>{`2. あなたにとって冒険とは？`}</h3>
    <p>{`冒険とは、未知の要素が多いことである。`}</p>
    <p>{`冒険には、初めの一歩を出す必要があります。結果として、良い場合も悪い場合もありますが、いつだって冒険のプロセスの間は、常にポジティブで希望に満ちています。そして結果が成功することを常に頭に留めておきます。`}</p>
    <Slideshow mdxType="Slideshow" />
    <h3>{`3. あなたが最近始めた特別な冒険は何ですか？`}</h3>
    <p>{`私は職業をタトゥーアーティストに切り替えて以来、私自身の冒険が始まりました。 初めは自分の生計を立てられるかどうか、顧客が私の仕事に満足してもらえるか不安でしたが、自分の決断には忠実であるようにしていました。自分がタトゥーアーティストになると決めた後は、タトゥースキルを磨き続け、途中で決して諦めませんでした。だから今日まで私は学び続け、自分自身を向上させています。`}</p>
    <blockquote>
      <h3 parentName="blockquote">{`しかし、タトゥーを完成させる度に、いつもお客様と何かを経験したような気がしています。これは、冒険から得られる小さな驚きでもあります。`}</h3>
      <p parentName="blockquote">{`Ah Gi`}</p>
    </blockquote>
    <h3>{`4. 冒険の最大の課題と、それらを克服するための秘訣は何ですか？`}</h3>
    <p>{`忍耐。 私の分野では、特にデザインについてお客様とコミュニケーションすることがとても大事です。デザインの自由は私が予想したよりもはるかに少なく、 お客様のニーズを理解するには、多くの忍耐が必要です。 しかし、タトゥーを完成させる度に、いつもお客様と何かを経験したような気がしています。これは、冒険から得られる小さな驚きでもあります。`}</p>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "1200px"
      }
    }}>{`
      `}<a parentName="span" {...{
        "className": "gatsby-resp-image-link",
        "href": "/static/b591aba065d844c53d08dff67d93ec5c/d2c6a/image.jpg",
        "style": {
          "display": "block"
        },
        "target": "_blank",
        "rel": "noopener"
      }}>{`
    `}<span parentName="a" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "55.99999999999999%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAALABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAMEBf/EABUBAQEAAAAAAAAAAAAAAAAAAAED/9oADAMBAAIQAxAAAAHRymLpK8iE/8QAGRABAAMBAQAAAAAAAAAAAAAAAgABERMS/9oACAEBAAEFAuZyniZM6KbPauf/xAAYEQACAwAAAAAAAAAAAAAAAAAAAQISUf/aAAgBAwEBPwFTWFkf/8QAFxEBAQEBAAAAAAAAAAAAAAAAAQACEv/aAAgBAgEBPwFy3Lf/xAAaEAACAgMAAAAAAAAAAAAAAAAAMQERECJB/9oACAEBAAY/AlCN6oio4PDP/8QAHBAAAgICAwAAAAAAAAAAAAAAAREAIRCRMUFh/9oACAEBAAE/IRa1InQW6FmXIQCBw281Co+NCf/aAAwDAQACAAMAAAAQFy//xAAYEQEBAAMAAAAAAAAAAAAAAAABABEhMf/aAAgBAwEBPxALnaUeX//EABYRAQEBAAAAAAAAAAAAAAAAAAERAP/aAAgBAgEBPxBZLgCXf//EABwQAQACAwADAAAAAAAAAAAAAAEAESFBUXHB0f/aAAgBAQABPxBoVNHpahiaqgKPUG1cwO3K9sYGiLKo3x+TNBpowcn/2Q==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="a" {...{
          "className": "gatsby-resp-image-image",
          "alt": "profile",
          "title": "profile",
          "src": "/static/b591aba065d844c53d08dff67d93ec5c/5a523/image.jpg",
          "srcSet": ["/static/b591aba065d844c53d08dff67d93ec5c/37e03/image.jpg 300w", "/static/b591aba065d844c53d08dff67d93ec5c/2a8be/image.jpg 600w", "/static/b591aba065d844c53d08dff67d93ec5c/5a523/image.jpg 1200w", "/static/b591aba065d844c53d08dff67d93ec5c/96503/image.jpg 1800w", "/static/b591aba065d844c53d08dff67d93ec5c/e49a0/image.jpg 2400w", "/static/b591aba065d844c53d08dff67d93ec5c/d2c6a/image.jpg 4168w"],
          "sizes": "(max-width: 1200px) 100vw, 1200px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
  `}</a>{`
    `}</span>
    <h3>{`5. なぜティーニーテールメイトを選んだのですか？`}</h3>
    <p>{`ティーニーテールメイトを選んだのは、このバッグから「愛は決して年をとらない」と言っているように感じられたからです。自分の心の目を開いて、自分の周りからもっと学びを得られるようにしています。`}</p>
    <h3>{`6. デザインの背後にあるストーリーは何ですか？`}</h3>
    <p>{`ミスマッチなデザインのバッグは、とてもユニークで個性豊かです。その為、対照的なカラーだけでなく、デザイン自体が強調できるカラーを使用してみました。`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      
import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Slideshow = makeShortcode("Slideshow");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h3>{`1. 請你介紹一下自己`}</h3>
    <p>{`我是一名刺青師，自小喜愛創作，在讀書時期親身體驗了人生第一次的紋身，那份獨特的感覺與喜悅從此烙印在心頭。畢業後，我當過記者，也從事過行銷類的工作，在營營役役的生活裡開始思考工作的意義，發現這一切都不是我真正喜歡的事。最後我決定忠於自己，重拾對設計的興趣，成為一名女刺青師。`}</p>
    <h3>{`2. 冒險對你的意義是什麼?`}</h3>
    <p>{`冒險，就是去做充滿未知數的事。`}</p>
    <p>{`我覺得冒險當中有很多不確定性，是需要你去勇於踏出第一步去嘗試，結果可以是好或是壞，但在冒險的過程中，保持正向的能量與希望，都會有機會得到好的結果。`}</p>
    <Slideshow mdxType="Slideshow" />
    <h3>{`3. 請跟我們分享你最近一次難忘的冒險`}</h3>
    <p>{`我認為在我轉行當刺青師開始，就已經踏上冒險的旅程。不知道能否維生，不知道客人是否喜歡我的作品，但我依然堅持著我的興趣。所以我跟自己說，一旦決定了，就要將紋身這門手藝學好，絕不能半途而廢，現在我仍不斷進修中。`}</p>
    <blockquote>
      <h3 parentName="blockquote">{`每完成一個作品，都好像跟客人共同經歷了一件事，成就感足以平復先前的辛勞，這也是冒險所帶來的驚喜。`}</h3>
      <p parentName="blockquote">{`Ah Gi`}</p>
    </blockquote>
    <h3>{`4. 你在冒險中遇到最大的困難是什麼? 你怎樣去克服它？`}</h3>
    <p>{`困難一定會有，特別是跟客人溝通設計的時候，能發揮的空間往往比想像的還要狹隘，也需要花很多心思去了解客人的需求。不過每完成一個作品，都好像跟客人共同經歷了一件事，成就感足以平復先前的辛勞，這也是冒險所帶來的驚喜。`}</p>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "1200px"
      }
    }}>{`
      `}<a parentName="span" {...{
        "className": "gatsby-resp-image-link",
        "href": "/static/b591aba065d844c53d08dff67d93ec5c/d2c6a/image.jpg",
        "style": {
          "display": "block"
        },
        "target": "_blank",
        "rel": "noopener"
      }}>{`
    `}<span parentName="a" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "55.99999999999999%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAALABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAMEBf/EABUBAQEAAAAAAAAAAAAAAAAAAAED/9oADAMBAAIQAxAAAAHRymLpK8iE/8QAGRABAAMBAQAAAAAAAAAAAAAAAgABERMS/9oACAEBAAEFAuZyniZM6KbPauf/xAAYEQACAwAAAAAAAAAAAAAAAAAAAQISUf/aAAgBAwEBPwFTWFkf/8QAFxEBAQEBAAAAAAAAAAAAAAAAAQACEv/aAAgBAgEBPwFy3Lf/xAAaEAACAgMAAAAAAAAAAAAAAAAAMQERECJB/9oACAEBAAY/AlCN6oio4PDP/8QAHBAAAgICAwAAAAAAAAAAAAAAAREAIRCRMUFh/9oACAEBAAE/IRa1InQW6FmXIQCBw281Co+NCf/aAAwDAQACAAMAAAAQFy//xAAYEQEBAAMAAAAAAAAAAAAAAAABABEhMf/aAAgBAwEBPxALnaUeX//EABYRAQEBAAAAAAAAAAAAAAAAAAERAP/aAAgBAgEBPxBZLgCXf//EABwQAQACAwADAAAAAAAAAAAAAAEAESFBUXHB0f/aAAgBAQABPxBoVNHpahiaqgKPUG1cwO3K9sYGiLKo3x+TNBpowcn/2Q==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="a" {...{
          "className": "gatsby-resp-image-image",
          "alt": "profile",
          "title": "profile",
          "src": "/static/b591aba065d844c53d08dff67d93ec5c/5a523/image.jpg",
          "srcSet": ["/static/b591aba065d844c53d08dff67d93ec5c/37e03/image.jpg 300w", "/static/b591aba065d844c53d08dff67d93ec5c/2a8be/image.jpg 600w", "/static/b591aba065d844c53d08dff67d93ec5c/5a523/image.jpg 1200w", "/static/b591aba065d844c53d08dff67d93ec5c/96503/image.jpg 1800w", "/static/b591aba065d844c53d08dff67d93ec5c/e49a0/image.jpg 2400w", "/static/b591aba065d844c53d08dff67d93ec5c/d2c6a/image.jpg 4168w"],
          "sizes": "(max-width: 1200px) 100vw, 1200px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
  `}</a>{`
    `}</span>
    <h3>{`5. 你為什麼會選擇這個包?`}</h3>
    <p>{`Teeny Tailmate 這個袋給我歷久彌新的感覺，其實就像平常面對生活一樣，每天都要保持坦率的態度，才能吸取更多養分。`}</p>
    <h3>{`6. 你這個設計背後的故事是什麼?`}</h3>
    <p>{`Teeny Tailmate本身的併接設計就很有特色，我想嘗試用顏色去凸顯它的剪裁，於是做了跳色設計。`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      
import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Slideshow = makeShortcode("Slideshow");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h3>{`1. 請你介紹一下自己`}</h3>
    <p>{`我是一名婚禮攝影師。為了實現夢想，在2019年創立了《七黑DARK 7》的暗房，主旨在於學習如何在暗房中拍攝，也希望能讓更多人體驗在暗房裡沖洗照片的樂趣。`}</p>
    <h3>{`2. 冒險對你的意義是什麼?`}</h3>
    <p>{`要贏，就要有勇於冒險精神。`}</p>
    <p>{`要贏就要有勇於冒險精神。我天生是一個愛胡思亂想、天馬行空又怕無聊的人，所以我很喜歡嘗試，經常在冒險的過程中跟自己說“WHY NOT?”。而這勇於挑戰的精神，讓我的生活更精彩。`}</p>
    <Slideshow mdxType="Slideshow" />
    <h3>{`3. 請跟我們分享你最近一次難忘的冒險`}</h3>
    <p>{`擁有自己的暗房，是我在國外進修攝影時，一直深埋在心中的夢想。機緣巧合之下，我有機會展開我的神祕暗房之旅。`}</p>
    <blockquote>
      <h3 parentName="blockquote">{`我從來沒有想過放棄，因為我不希望暗房從世上消失，至少能在香港被保留。`}</h3>
      <p parentName="blockquote">{`Ann Choi`}</p>
    </blockquote>
    <h3>{`4. 你在冒險中遇到最大的困難是什麼? 你怎樣去克服它？`}</h3>
    <p>{`經營一個暗房真的比想像中還要困難，很難凝聚一群有心人。但我從來沒有想過放棄，因為我不希望暗房從世上消失，至少能在香港被保留。`}</p>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "1200px"
      }
    }}>{`
      `}<a parentName="span" {...{
        "className": "gatsby-resp-image-link",
        "href": "/static/b2c5d0bedb15ab9fc385ddf133fdddd5/d2c6a/image.jpg",
        "style": {
          "display": "block"
        },
        "target": "_blank",
        "rel": "noopener"
      }}>{`
    `}<span parentName="a" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "55.99999999999999%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAALABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAQFAv/EABcBAAMBAAAAAAAAAAAAAAAAAAACAwT/2gAMAwEAAhADEAAAAaM4xPU8Jg3/xAAZEAADAQEBAAAAAAAAAAAAAAAAAQITEBL/2gAIAQEAAQUCzkuknUo0rnumf//EABgRAAMBAQAAAAAAAAAAAAAAAAACURIT/9oACAEDAQE/Aeb0w1P/xAAWEQEBAQAAAAAAAAAAAAAAAAAAEQH/2gAIAQIBAT8BuK//xAAaEAACAgMAAAAAAAAAAAAAAAAAMSFBAQIR/9oACAEBAAY/AlhEcNYoZQz/xAAcEAACAgIDAAAAAAAAAAAAAAABEQAhMWEQcZH/2gAIAQEAAT8hCXVpCBl6xBQQGxPDevEOZhQn/9oADAMBAAIAAwAAABAoD//EABcRAQEBAQAAAAAAAAAAAAAAAAEAEbH/2gAIAQMBAT8Qyd6YMn//xAAWEQEBAQAAAAAAAAAAAAAAAAAAEVH/2gAIAQIBAT8QsjH/xAAdEAACAgMAAwAAAAAAAAAAAAABEQAhMUFRcaHR/9oACAEBAAE/EDBKsPTUzf2EtdeoGxeIZJi9qpoTfYXj8ip5TwDk/9k=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="a" {...{
          "className": "gatsby-resp-image-image",
          "alt": "profile",
          "title": "profile",
          "src": "/static/b2c5d0bedb15ab9fc385ddf133fdddd5/5a523/image.jpg",
          "srcSet": ["/static/b2c5d0bedb15ab9fc385ddf133fdddd5/37e03/image.jpg 300w", "/static/b2c5d0bedb15ab9fc385ddf133fdddd5/2a8be/image.jpg 600w", "/static/b2c5d0bedb15ab9fc385ddf133fdddd5/5a523/image.jpg 1200w", "/static/b2c5d0bedb15ab9fc385ddf133fdddd5/96503/image.jpg 1800w", "/static/b2c5d0bedb15ab9fc385ddf133fdddd5/e49a0/image.jpg 2400w", "/static/b2c5d0bedb15ab9fc385ddf133fdddd5/d2c6a/image.jpg 4168w"],
          "sizes": "(max-width: 1200px) 100vw, 1200px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
  `}</a>{`
    `}</span>
    <h3>{`5. 你為什麼會選擇這個包?`}</h3>
    <p>{`相機、手機、太陽眼鏡是我的日常隨身物品，Sacoche貼身且輕巧的包型設計，不僅很方便使用，更是街頭穿搭的必備單品。`}</p>
    <h3>{`6. 你這個設計背後的故事是什麼?`}</h3>
    <p>{`我的設計低調外也保有獨特的風格，是我日常生活的百搭配色。`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      
import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Slideshow = makeShortcode("Slideshow");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h3>{`1.Who is アン?`}</h3>
    <p>{`私はウェディングフォトグラファーです。2019年に夢を叶えるために「Seven Black Dark 7」という暗 室を作りました。映画の作り方を学ぶワークショップを開催し、沢山の人に暗室の楽しさを体験しても らいました。`}</p>
    <h3>{`2. あなたにとって冒険とは？`}</h3>
    <p>{`冒険とは、人生に勝つために必要なものです。私は退屈を怖がる夢想家なので、新しいことに挑戦し、その過程では「なぜしないのか」と自問自答したりすることもあります。冒険が人生をよりカラフルにしてくれるのです。`}</p>
    <Slideshow mdxType="Slideshow" />
    <h3>{`3. あなたが最近始めた特別な冒険は何ですか？`}</h3>
    <p>{`自分の暗室を持つことです。海外で写真を勉強して以来、それは私の夢でした。でもその夢はいつしか心の奥底に忘れてしまっていました。しかし幸いにも、このワークショップの機会を得ることができ、私は自分の暗い部屋を持つという冒険を始めることができたのです。`}</p>
    <blockquote>
      <h3 parentName="blockquote">{`私は世界や香港から暗室が消えるのを見たくないので、これを諦めることを考えたことはありません。`}</h3>
      <p parentName="blockquote">{`Ann Choi`}</p>
    </blockquote>
    <h3>{`4. より冒険的になるための最大の課題と、それらを克服するための秘訣は何ですか？`}</h3>
    <p>{`管理すること。暗室の管理は、予想よりもはるかに困難です。最も難しいのは、同じトピックに情熱を持っている個人グループをまとめることです。私は世界や香港から暗室が消えるのを見たくないので、これを諦めることを考えたことはありません。`}</p>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "1200px"
      }
    }}>{`
      `}<a parentName="span" {...{
        "className": "gatsby-resp-image-link",
        "href": "/static/b2c5d0bedb15ab9fc385ddf133fdddd5/d2c6a/image.jpg",
        "style": {
          "display": "block"
        },
        "target": "_blank",
        "rel": "noopener"
      }}>{`
    `}<span parentName="a" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "55.99999999999999%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAALABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAQFAv/EABcBAAMBAAAAAAAAAAAAAAAAAAACAwT/2gAMAwEAAhADEAAAAaM4xPU8Jg3/xAAZEAADAQEBAAAAAAAAAAAAAAAAAQITEBL/2gAIAQEAAQUCzkuknUo0rnumf//EABgRAAMBAQAAAAAAAAAAAAAAAAACURIT/9oACAEDAQE/Aeb0w1P/xAAWEQEBAQAAAAAAAAAAAAAAAAAAEQH/2gAIAQIBAT8BuK//xAAaEAACAgMAAAAAAAAAAAAAAAAAMSFBAQIR/9oACAEBAAY/AlhEcNYoZQz/xAAcEAACAgIDAAAAAAAAAAAAAAABEQAhMWEQcZH/2gAIAQEAAT8hCXVpCBl6xBQQGxPDevEOZhQn/9oADAMBAAIAAwAAABAoD//EABcRAQEBAQAAAAAAAAAAAAAAAAEAEbH/2gAIAQMBAT8Qyd6YMn//xAAWEQEBAQAAAAAAAAAAAAAAAAAAEVH/2gAIAQIBAT8QsjH/xAAdEAACAgMAAwAAAAAAAAAAAAABEQAhMUFRcaHR/9oACAEBAAE/EDBKsPTUzf2EtdeoGxeIZJi9qpoTfYXj8ip5TwDk/9k=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="a" {...{
          "className": "gatsby-resp-image-image",
          "alt": "profile",
          "title": "profile",
          "src": "/static/b2c5d0bedb15ab9fc385ddf133fdddd5/5a523/image.jpg",
          "srcSet": ["/static/b2c5d0bedb15ab9fc385ddf133fdddd5/37e03/image.jpg 300w", "/static/b2c5d0bedb15ab9fc385ddf133fdddd5/2a8be/image.jpg 600w", "/static/b2c5d0bedb15ab9fc385ddf133fdddd5/5a523/image.jpg 1200w", "/static/b2c5d0bedb15ab9fc385ddf133fdddd5/96503/image.jpg 1800w", "/static/b2c5d0bedb15ab9fc385ddf133fdddd5/e49a0/image.jpg 2400w", "/static/b2c5d0bedb15ab9fc385ddf133fdddd5/d2c6a/image.jpg 4168w"],
          "sizes": "(max-width: 1200px) 100vw, 1200px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
  `}</a>{`
    `}</span>
    <h3>{`5. サコッシュを選んだ理由は？`}</h3>
    <p>{`このサコッシュは体にぴったりとフィットし、かさ張ることがないからです。普段私はフィルムカメラ、携帯電話、サングラスを携帯していますが、サコッシュは非常に持ち運びが簡単で、ストリートスナップの持ち物としても機能しています。`}</p>
    <h3>{`6. デザインの背後にあるストーリーは何ですか？`}</h3>
    <p>{`控えめにしたいと同時にアイキャッチ―にしたかったので、紫、赤、ネイビーの色を選びました。普段使いにもマッチします。`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      
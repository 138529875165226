import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { connect } from "react-redux"
import { updateLang, updateRegion } from '../actions';
import { Button, Grid } from '@material-ui/core'
import { defaultStaticContext } from '../context/StaticContext'
import logo from '../images/logo.png'

const mapStateToProps = ({ currentLang, currentRegion }) => {
  return { currentLang, currentRegion }
}

const mapDispatchToProps = dispatch => ({
  updateLang: langValue => dispatch(updateLang(langValue)),
  updateRegion: regionValue => dispatch(updateRegion(regionValue))
})

const LanguageBox = ({ display }) => {

  // function handleClick(lang, region) {
  //   i18n.changeLanguage(lang)
  //   hideHandleClick(lang)
  //   setValues(oldValues => ({
  //     ...oldValues,
  //     language: lang
  //   }));
  //   updateLang(lang)
  //   updateRegion(region)
  // }

  const langList = [
    { region: "hk", lang: "en"},
    { region: "jp", lang: "jp"},
    { region: "tw", lang: "tc"}
  ]

  return(
    <div className={display?"langBox hide":"langBox show"}>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={2}
        className="langBox__container"
      >
        <Grid item className="langBox__item langBox__logo">
          <img src={logo} />
        </Grid>
        <Grid item className="langBox__item">
          <span className="langBox__text">
            {/* { t('langBox.instruction') } */}
            Please select your region. Payment & readiness will be performed here<br/>
            請選擇您的所在地，後續的付款與取貨將於您選擇的所在地完成<br/>
            あなたの国を選んでください
          </span>
        </Grid>
        {langList.map((item, i) => (
          <Grid
            item key={i}
            className="langBox__item">
            <a variant="outlined" href={"https://" + item.region + ".createmygregory.com"}>{defaultStaticContext.regionlist[item.region]}</a>
          </Grid>
        ))}
      </Grid>
      {/* <div className="langBox__blueBg"/>
      <div className="langBox__greenBg"/>
      <div className="langBox__yellowBg"/> */}
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(LanguageBox)

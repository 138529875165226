import React from 'react'
import { useStaticQuery } from 'gatsby'

export const defaultStaticContext = {
  startDate: "2021-12-10",
  mode: "post-launch", // pre-launch, launch, post-launch
  social: {
    "hk": [
      { "icon": "website", "link": "http://www.gregorypacks.com.hk/", "name": "website" },
      { "icon": "fb", "link": "https://www.facebook.com/gregoryhongkong/", "name": "facebook" },
      { "icon": "ig", "link": "https://www.instagram.com/gregoryhongkong", "name": "instagram" }
    ],
    "tw": [
      { "icon": "fb", "link": "https://www.facebook.com/GregoryxTW/", "name": "facebook" },
      { "icon": "ig", "link": "https://www.instagram.com/gregorytaiwan/", "name": "instagram" }
    ],
    "jp": [
      { "icon": "website", "link": "https://www.gregory.jp/", "name": "website" },
      { "icon": "fb", "link": "https://business.facebook.com/GregoryJapan/", "name": "facebook" },
      { "icon": "ig", "link": "https://www.instagram.com/gregoryjapan/", "name": "instagram" },
      { "icon": "youtube", "link": "https://www.youtube.com/user/GregoryJapan/", "name": "youtube" }
    ],
    "sg": [
      { "icon": "website", "link": "https://www.facebook.com/GregorySingapore/", "name": "website" },
      { "icon": "ig", "link": "https://www.instragram.com/gregorysingapore", "name": "instagram" }
    ]
  },
  price: {
    "hk": {
      "sacoche": 380,
      "quick-pocket": 510,
      "unit": "HKD"
    },
    "tw": {
      "sacoche": 1880,
      "quick-pocket": 2180,
      "unit": "TWD"
    },
    "jp": {
      "sacoche": 7150,
      "quick-pocket": 7150,
      "unit": "JPY"
    }
  },
  langList: {
    'en': 'Engish',
    'tc': '繁體中文',
    'jp': '日本語'
  },
  regionlist: {
    "hk": "Hong Kong 香港",
    "jp": "Japan 日本",
    "tw": "Taiwan 台灣",
  },
  storemap: {
    "hk": ["hk1","hk2","hk3"],
    "tw": ["tw1","tw2","tw4","tw5","tw6"],
    "jp": ["jp1","jp2","jp3","jp4"]
  },
  timemap: {
    "hk": ["time3","time4","time5","time6","time7","time8","time9","time10","time11"],
    "tw": ["time4","time5","time6","time7","time8","time9","time10"],
    "jp": ["time1","time2","time3","time4","time5","time6","time7","time8","time9","time10"],
  },
  datelist: {
    "date1": { "full": "17 December 2021", "day": 17 },
    "date2": { "full": "18 December 2021", "day": 18 },
    "date3": { "full": "19 December 2021", "day": 19 }
  },
  timelist: [ "10:00", "11:00", "12:00", "13:00", "14:00", "15:00", "16:00", "17:00", "18:00", "19:00", "20:00" ],
  design: {
    sacoche : [{
      name: "Design 1",
      case: [
        { comp: "front upper panel", color: "beige" },
        { comp: "front middle panel", color: "grey" },
        { comp: "front lower panel", color: "black" },
        { comp: "nylon tape", color: "black_t" },
        { comp: "back panel", color: "black" },
        { comp: "zipper", color: "black_z" },
        { comp: "zipper puller", color: "black_p" },
        { comp: "label", color: "dark" }
      ]
    },
    {
      name: "Design 2",
      case: [
        { comp: "front upper panel", color: "violet" },
        { comp: "front middle panel", color: "violet" },
        { comp: "front lower panel", color: "garden" },
        { comp: "nylon tape", color: "purple_t" },
        { comp: "back panel", color: "grey" },
        { comp: "zipper", color: "black_z" },
        { comp: "zipper puller", color: "brown_p" },
        { comp: "label", color: "classic" }
      ]
    },
    {
      name: "Design 3",
      case: [
        { comp: "front upper panel", color: "green" },
        { comp: "front middle panel", color: "beige" },
        { comp: "front lower panel", color: "rust" },
        { comp: "nylon tape", color: "purple_t" },
        { comp: "back panel", color: "grey" },
        { comp: "zipper", color: "white_z" },
        { comp: "zipper puller", color: "brown_p" },
        { comp: "label", color: "dark" }
      ]
    },
    {
      name: "Design 4",
      case: [
        { comp: "front upper panel", color: "garden" },
        { comp: "front middle panel", color: "rust" },
        { comp: "front lower panel", color: "garden" },
        { comp: "nylon tape", color: "green_t" },
        { comp: "back panel", color: "garden" },
        { comp: "zipper", color: "white_z" },
        { comp: "zipper puller", color: "black_p" },
        { comp: "label", color: "dark" }
      ]
    }],
    'quick-pocket': [{
      name: "Design 1",
      case: [
        { comp: "front upper panel", color: "beige" },
        { comp: "front lower panel", color: "beige" },
        { comp: "side upper panel", color: "black" },
        { comp: "side lower panel", color: "black" },
        { comp: "back panel", color: "beige" },
        { comp: "zipper", color: "white_z" },
        { comp: "zipper puller", color: "grey_p" },
        { comp: "label", color: "classic" }
      ]
    },
    {
      name: "Design 2",
      case: [
        { comp: "front upper panel", color: "rust" },
        { comp: "front lower panel", color: "green" },
        { comp: "side upper panel", color: "garden" },
        { comp: "side lower panel", color: "garden" },
        { comp: "back panel", color: "black" },
        { comp: "zipper", color: "black_z" },
        { comp: "zipper puller", color: "brown_p" },
        { comp: "label", color: "classic" }
      ]
    },
    {
      name: "Design 3",
      case: [
        { comp: "front upper panel", color: "black" },
        { comp: "front lower panel", color: "black" },
        { comp: "side upper panel", color: "grey" },
        { comp: "side lower panel", color: "garden" },
        { comp: "back panel", color: "black" },
        { comp: "zipper", color: "black_z" },
        { comp: "zipper puller", color: "black_p" },
        { comp: "label", color: "dark" }
      ]
    },
    {
      name: "Design 4",
      case: [
        { comp: "front upper panel", color: "beige" },
        { comp: "front lower panel", color: "rust" },
        { comp: "side upper panel", color: "garden" },
        { comp: "side lower panel", color: "beige" },
        { comp: "back panel", color: "rust" },
        { comp: "zipper", color: "white_z" },
        { comp: "zipper puller", color: "grey_p" },
        { comp: "label", color: "classic" }
      ]
    }]
  }
}

const StaticContext = React.createContext(defaultStaticContext)

export default StaticContext

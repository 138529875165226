import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Slideshow = makeShortcode("Slideshow");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h3>{`1. Who is Ah Gi?`}</h3>
    <p>{`I am a tattoo artist. I’ve loved all things creation since I was a kid. I had my first tattoo when I was studying and that unique feeling and joy afterwards stuck in my mind. After graduation, I worked as a reporter and also worked in marketing. During my busy life, I started thinking about the meaning of work and realized that what I was doing was not something I really loved. In the end, I decided to stick with my belief and pick up my passion for design again and became a female tattoo artist!`}</p>
    <h3>{`2. What does adventure mean to you?`}</h3>
    <p>{`Adventure is doing things that has a lot of unknowns.`}</p>
    <p>{`There are many uncertainties in adventures, but you need to make the first step to try. The result can be good or bad, but during the process it is always filled with positivity and hope, and you keep your head up that the results will be successful.`}</p>
    <Slideshow mdxType="Slideshow" />
    <h3>{`3. What is a special adventure you have embarked upon recently?`}</h3>
    <p>{`Since I switched my occupation to becoming a tattoo artist, I have started my very own adventure. I was not sure whether I could make a living and whether customers would like my work, however, it was a decision that I made and stayed true to myself. I told myself, once I made the decision, I would keep enhancing my tattoo skills and never give up in the middle of the journey. That’s why up until today, I keep learning and improving on myself.`}</p>
    <blockquote>
      <h3 parentName="blockquote">{`The satisfaction I get far exceeds the pain during the process. This is also an example of the little surprises you get from adventures.`}</h3>
      <p parentName="blockquote">{`Ah Gi`}</p>
    </blockquote>
    <h3>{`4. What is your biggest challenge to becoming more adventurous & your secret to overcoming them?`}</h3>
    <p>{`Patience. Encountering challenges is a must in my field, especially when communicating with customers about their design. The creative freedom is much less than I anticipated. You need a lot of patience to understand the needs and requirements of customers. However, everytime I complete a piece of work, I feel like I have experienced something with my customers. The satisfaction I get far exceeds the pain during the process. This is also an example of the little surprises you get from adventures.`}</p>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "1200px"
      }
    }}>{`
      `}<a parentName="span" {...{
        "className": "gatsby-resp-image-link",
        "href": "/static/b591aba065d844c53d08dff67d93ec5c/d2c6a/image.jpg",
        "style": {
          "display": "block"
        },
        "target": "_blank",
        "rel": "noopener"
      }}>{`
    `}<span parentName="a" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "55.99999999999999%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAALABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAMEBf/EABUBAQEAAAAAAAAAAAAAAAAAAAED/9oADAMBAAIQAxAAAAHRymLpK8iE/8QAGRABAAMBAQAAAAAAAAAAAAAAAgABERMS/9oACAEBAAEFAuZyniZM6KbPauf/xAAYEQACAwAAAAAAAAAAAAAAAAAAAQISUf/aAAgBAwEBPwFTWFkf/8QAFxEBAQEBAAAAAAAAAAAAAAAAAQACEv/aAAgBAgEBPwFy3Lf/xAAaEAACAgMAAAAAAAAAAAAAAAAAMQERECJB/9oACAEBAAY/AlCN6oio4PDP/8QAHBAAAgICAwAAAAAAAAAAAAAAAREAIRCRMUFh/9oACAEBAAE/IRa1InQW6FmXIQCBw281Co+NCf/aAAwDAQACAAMAAAAQFy//xAAYEQEBAAMAAAAAAAAAAAAAAAABABEhMf/aAAgBAwEBPxALnaUeX//EABYRAQEBAAAAAAAAAAAAAAAAAAERAP/aAAgBAgEBPxBZLgCXf//EABwQAQACAwADAAAAAAAAAAAAAAEAESFBUXHB0f/aAAgBAQABPxBoVNHpahiaqgKPUG1cwO3K9sYGiLKo3x+TNBpowcn/2Q==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="a" {...{
          "className": "gatsby-resp-image-image",
          "alt": "profile",
          "title": "profile",
          "src": "/static/b591aba065d844c53d08dff67d93ec5c/5a523/image.jpg",
          "srcSet": ["/static/b591aba065d844c53d08dff67d93ec5c/37e03/image.jpg 300w", "/static/b591aba065d844c53d08dff67d93ec5c/2a8be/image.jpg 600w", "/static/b591aba065d844c53d08dff67d93ec5c/5a523/image.jpg 1200w", "/static/b591aba065d844c53d08dff67d93ec5c/96503/image.jpg 1800w", "/static/b591aba065d844c53d08dff67d93ec5c/e49a0/image.jpg 2400w", "/static/b591aba065d844c53d08dff67d93ec5c/d2c6a/image.jpg 4168w"],
          "sizes": "(max-width: 1200px) 100vw, 1200px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
  `}</a>{`
    `}</span>
    <h3>{`5. Why did you choose the Teeny Tailmate bag?`}</h3>
    <p>{`I chose the Teeny Tailmate because this bag gave me a feeling of “love never growing old”. Similar to everyday life, we need to have an open mind for us to learn more from our surroundings.`}</p>
    <h3>{`6. What is the story behind your design?`}</h3>
    <p>{`The mis-matched design of the bag makes it very unique and full of character. That’s why I tried to use colors that aren’t just contrasting colors, but that also highlight the design itself. `}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      
import React from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from 'react-redux'
import { defaultStaticContext } from '../context/StaticContext'

const ShareBox = () => {
  const { t } = useTranslation()
  const currentRegion = useSelector(state => state.currentRegion)
  const currentSocial = defaultStaticContext.social[currentRegion]
  return (
    <div className={"shareBox" + " shareBox__" + currentSocial.length}>
      {currentSocial.map((item, i) => (
        <a key={i} target="_blank" href={item.link} className="shareBox__item"><i className={'icon icon-' + item.icon}/>{ t(item.name) }</a>
      ))}
    </div>
  )
}

export default ShareBox

import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Slideshow = makeShortcode("Slideshow");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h3>{`1. Who is Ann?`}</h3>
    <p>{`I am a wedding photographer. In 2019, in order to fulfill my dreams, I created the dark room of "Dark 7", a workshop I held on learning how to develop films in the dark room. I loved to let more people experience ‘film washing’ from time to time to let them experience the fun of being in a dark room.`}</p>
    <h3>{`2. What does adventure mean to you?`}</h3>
    <p>{`Adventure is what you need in order to win.`}</p>
    <p>{`If you need to win, you will become adventurous. I’m born to become a person who has ‘blue sky’ thinking. I’m an open day dreamer that is really scared of boredom. That is why I like trying new things and ask myself “why not” during the process. This is what makes life more colorrful.`}</p>
    <Slideshow mdxType="Slideshow" />
    <h3>{`3. What is a special adventure you have embarked upon recently?`}</h3>
    <p>{`Owning my own darkroom. This has been a dream of mine since I studied photography overseas. However, I put it deep down in my heart that I almost forgot about it. Thankfully, because of chance, I have had this opportunity to start my mysterious journey of owning my own dark room.`}</p>
    <blockquote>
      <h3 parentName="blockquote">{`I’ve never thought about giving up as I don’t want to see dark rooms disappearing in the world, nor in Hong Kong.`}</h3>
      <p parentName="blockquote">{`Ann Choi`}</p>
    </blockquote>
    <h3>{`4. What is your biggest challenge to becoming more adventurous & your secret to overcoming them?`}</h3>
    <p>{`Management. Managing a dark room is much more difficult than I expected. The most difficult part is bringing a group of individuals who are passionate about the same topic all together. But, I’ve never thought about giving up as I don’t want to see dark rooms disappearing in the world, nor in Hong Kong.`}</p>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "1200px"
      }
    }}>{`
      `}<a parentName="span" {...{
        "className": "gatsby-resp-image-link",
        "href": "/static/b2c5d0bedb15ab9fc385ddf133fdddd5/d2c6a/image.jpg",
        "style": {
          "display": "block"
        },
        "target": "_blank",
        "rel": "noopener"
      }}>{`
    `}<span parentName="a" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "55.99999999999999%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAALABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAQFAv/EABcBAAMBAAAAAAAAAAAAAAAAAAACAwT/2gAMAwEAAhADEAAAAaM4xPU8Jg3/xAAZEAADAQEBAAAAAAAAAAAAAAAAAQITEBL/2gAIAQEAAQUCzkuknUo0rnumf//EABgRAAMBAQAAAAAAAAAAAAAAAAACURIT/9oACAEDAQE/Aeb0w1P/xAAWEQEBAQAAAAAAAAAAAAAAAAAAEQH/2gAIAQIBAT8BuK//xAAaEAACAgMAAAAAAAAAAAAAAAAAMSFBAQIR/9oACAEBAAY/AlhEcNYoZQz/xAAcEAACAgIDAAAAAAAAAAAAAAABEQAhMWEQcZH/2gAIAQEAAT8hCXVpCBl6xBQQGxPDevEOZhQn/9oADAMBAAIAAwAAABAoD//EABcRAQEBAQAAAAAAAAAAAAAAAAEAEbH/2gAIAQMBAT8Qyd6YMn//xAAWEQEBAQAAAAAAAAAAAAAAAAAAEVH/2gAIAQIBAT8QsjH/xAAdEAACAgMAAwAAAAAAAAAAAAABEQAhMUFRcaHR/9oACAEBAAE/EDBKsPTUzf2EtdeoGxeIZJi9qpoTfYXj8ip5TwDk/9k=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="a" {...{
          "className": "gatsby-resp-image-image",
          "alt": "profile",
          "title": "profile",
          "src": "/static/b2c5d0bedb15ab9fc385ddf133fdddd5/5a523/image.jpg",
          "srcSet": ["/static/b2c5d0bedb15ab9fc385ddf133fdddd5/37e03/image.jpg 300w", "/static/b2c5d0bedb15ab9fc385ddf133fdddd5/2a8be/image.jpg 600w", "/static/b2c5d0bedb15ab9fc385ddf133fdddd5/5a523/image.jpg 1200w", "/static/b2c5d0bedb15ab9fc385ddf133fdddd5/96503/image.jpg 1800w", "/static/b2c5d0bedb15ab9fc385ddf133fdddd5/e49a0/image.jpg 2400w", "/static/b2c5d0bedb15ab9fc385ddf133fdddd5/d2c6a/image.jpg 4168w"],
          "sizes": "(max-width: 1200px) 100vw, 1200px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
  `}</a>{`
    `}</span>
    <h3>{`5. Why did you choose the Sacoche bag?`}</h3>
    <p>{`This Sacoche bag sits perfectly close to my body and is not bulky. Usually I will carry a film camera, my phone and a pair of sunglasses with me. The Sacoche is very easy to carry around and acts as my companion for my street snapshots.`}</p>
    <h3>{`6. What is the story behind your design?`}</h3>
    <p>{`I want to maintain a low profile but at the same time be eye-catching, which is why I have chosen the colours of ultra violet, red and navy. They are also quite versatile and can match with my daily style.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      